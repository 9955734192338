import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M26.8,59.8V29.1h5.8v13.8h0.3l10.2-13.8h6.4l-10,13.4l10.7,17.3h-6.9l-8-13l-2.7,3.7v9.4H26.8z"
          fill="#64FFDA"
        />
        <polygon
          id="Shape"
          stroke="#64FFDA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
